import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IWizardForm } from '../../../interfaces';

@Component({ template: '' })
export abstract class WizardStepBaseComponent {
    protected get formControl(): UntypedFormControl {
        return (this.stepForm as any)?.form as UntypedFormControl;
    }

    public visible: boolean;
    public nextPageLink: string = null;

    @Input()
    public form: IWizardForm;

    @Output()
    public stepSubmit: EventEmitter<void> = new EventEmitter();

    @ViewChild('stepForm')
    public stepForm: ElementRef<UntypedFormControl>;

    public validateAndSubmitForm(): Observable<boolean> {
        return this.formControl
            ? this.validateFormPage().pipe(switchMap((isValid) => (isValid ? this.submitWizardStep() : of(false))))
            : this.submitWizardStep();
    }

    public show(): void {
        this.visible = true;
    }

    public hide(): void {
        this.visible = false;
    }

    protected abstract submitWizardStep(): Observable<boolean>;

    private validateFormPage(): Observable<boolean> {
        const formControl = this.formControl;

        formControl.updateValueAndValidity();
        formControl.markAllAsTouched();

        return of(!formControl.invalid);
    }
}
