import { Directive, ElementRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Directive({
    selector: '[ngModel][validationErrors]',
})
export class ValidationErrorsDirective {
    private readonly labelElement: HTMLLabelElement;
    private readonly errorContainer: HTMLDivElement;

    public constructor(
        private readonly elementRef: ElementRef,
        private readonly ngModel: NgModel,
        private readonly translate: TranslateService
    ) {
        this.labelElement = this.elementRef.nativeElement.parentElement;

        if (!this.labelElement || this.labelElement.tagName !== 'LABEL') {
            throw new Error('validationErrors directive must be used on a label element');
        }

        this.errorContainer = this.createErrorContainer();

        /* Listening to the value of ngModel */
        ngModel.valueChanges.subscribe(this.handleValueChange.bind(this));
    }

    private handleValueChange(): void {
        const container = this.errorContainer;
        const { style } = container;
        style.display = 'none';

        // Clear all errors from the error container
        while (container.firstChild) {
            container.removeChild(container.firstChild);
        }

        const errors = Object.entries(this.ngModel.control.errors ?? {});
        if (errors.length && this.ngModel.dirty) {
            errors.forEach(([error, errorMetaData]) => {
                const errorElement = document.createElement('div');
                errorElement.innerText = this.translate.instant(`validation.${error}`, errorMetaData);
                container.appendChild(errorElement);
            });
            style.display = 'block';
        }
    }

    private createErrorContainer(): HTMLDivElement {
        const container = document.createElement('div');
        container.classList.add('form-error');
        container.style.display = 'none';

        this.labelElement.appendChild(container);

        return container;
    }
}
