import { Component, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { vshrink } from 'shared';

import { AppSettingsService, TenantService } from '../../../services';
import { WizardMapComponent } from '../../wizard-map/wizard-map.component';
import { WizardStepBaseComponent } from '../base/wizard-step-base.component';

@Component({
    selector: 'app-wizard-step-location',
    templateUrl: './wizard-step-location.component.html',
    styleUrls: ['../base/wizard-step-base.component.scss', './wizard-step-location.component.scss'],
    animations: [vshrink()],
})
export class WizardStepLocationComponent extends WizardStepBaseComponent {
    @ViewChild(WizardMapComponent) public map: WizardMapComponent;
    public enableMap = true;

    public constructor(
        private readonly appSettings: AppSettingsService,
        private readonly tenantService: TenantService
    ) {
        super();
    }

    public geocodeLocation(): void {
        this.map.geocodeLocation(this.form.location);
    }

    protected submitWizardStep(): Observable<boolean> {
        const config = this.map.getSettings();
        if (this.enableMap) {
            return this.tenantService.updateConfig(config);
        } else {
            return of(true);
        }
    }
}
