import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppSettings } from '../../../models/app-settings.model';
import { AppSettingsService } from '../../../services';
import { WizardStepBaseComponent } from '../base/wizard-step-base.component';

@Component({
    selector: 'app-wizard-step-done',
    templateUrl: './wizard-step-done.component.html',
    styleUrls: ['../base/wizard-step-base.component.scss', './wizard-step-done.component.scss'],
})
export class WizardStepDoneComponent extends WizardStepBaseComponent {
    public url: AppSettings['url'];

    public constructor(appSettings: AppSettingsService) {
        super();

        appSettings
            .get(
                tap((settings) => {
                    this.url = settings.url;
                    this.nextPageLink = this.url.portal;
                })
            )
            .subscribe();
    }

    protected submitWizardStep(): Observable<boolean> {
        return of(false);
    }
}
